<div class="login-wrapper">
  <div class="card">
    <div class="card-body">
        <form class="form-signin" (ngSubmit)="onSubmit(signInForm)" #signInForm="ngForm">
          <img class="mb-4" src="./assets/img/Adheesa1.png" alt="" width="72" height="72">
          
          <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
          
          <label for="inputEmail" class="sr-only">Email address</label>
          <input type="email" id="inputEmail" name="email" class="form-control" 
          placeholder="Email address" required autofocus ngModel>
          
          <label for="inputPassword" class="sr-only">Password</label>
          <input type="password" id="inputPassword" name="password" class="form-control" 
          placeholder="Password" required ngModel>
          
          <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>


          <div class="alert alert-danger" role="alert" *ngIf="isFormInvalid">
            Please fill all fields
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="areCredentialsInvalid">
            Invalid user or password
          </div>
        </form>
    </div>
  </div>
</div>

