<div class="container-md">
    <h1>{{header}}</h1>

        <div class="container">
            <div class="card">
                <div class="card-header">
                    <div class="card-tools">
                        <div class="input-group input-group-sm" style="width: 200px;">
                            <input type="text"
                            class="form-control"
                            placeholder="Cari berdasarkan nama"
                            [(ngModel)]="nama"
                            (keydown.enter)="searchNama($event)"
                            (keyup)="searchNama($event)"
                            />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-outline-secondary" (click)="searchNama($event)"><fa-icon [icon]="faSearch"></fa-icon></button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.card-header -->
            
                <div class="card-body table-responsive p-0 " style="height: 430px;">
                    <table class="table table-head-fixed table-hover">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">ID</th>
                            <th scope="col">Tanggal</th>
                            <th scope="col">Nama</th>
                            <th scope="col">Jenis Pekerjaan</th>
                            <th scope="col">Jumlah</th>
                            <th scope="col">Lab</th>
                            <th scope="col">Biaya</th>
                            <th scope="col">DPJP</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let dataLab of dataLabs;let i = index" [class.active]="i == currentIndex" (click)="setActiveLab(dataLab, i)">
                    <!-- <tr *ngFor="let dataLab of dataLabs;let i = index" [class.active]="i == currentIndex" (click)="setActiveLab(dataLab, i)" [routerLink]="[ '/pasien', pasien.pasien_id ]"> -->

                        <th scope="row">{{ i + 1 }}</th>
                        <td> {{dataLab.pasien_id}}</td>
                        <td> {{dataLab.tanggal}} </td>
                        <td> {{dataLab.Pasien.nama_pasien}} </td>
                        <td> {{dataLab.jenis_pekerjaan}}</td>
                        <td> {{dataLab.jumlah}}</td>
                        <td> {{dataLab.lab}}</td>
                        <td> {{dataLab.biaya | currency : 'IDR' : 'Rp. '}}</td>
                        <td> {{dataLab.dpjp}}</td>
                        <td><button class="btn btn-sm btn-warning" (click)="open(content);setActiveData(dataLab, i)">Update</button></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
        </div>
        <h5>Hasil Pencarian : {{result}} pasien</h5>
        
        <ng-template #content let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Update Riwayat Kunjungan</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form style="margin-right: -8%;">
                    <div class="form-group row">
                        <label for="id" class="col-sm-4 col-form-label">ID</label>
                        <div class="col-sm-7">
                        <input type="text" class="form-control" id="id" [(ngModel)]="currentData.Pasien.pasien_id" required name="id" disabled>
                        </div>
                    
                    </div>
                    <div class="form-group row">
                        <label for="nama" class="col-sm-4 col-form-label">Nama</label>
                        <div class="col-sm-7">
                        <input type="text" class="form-control" id="nama" required [(ngModel)]="currentData.Pasien.nama_pasien" name="nama" disabled>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-4 col-form-label">Tanggal</label>
                        <div class="col-sm-7">
                            <div class="input-group">
                                <input class="form-control" placeholder="{{currentData.tanggal}}" name="dp" [(ngModel)]="currentData.tanggal" ngbDatepicker #d="ngbDatepicker" (ngModelChange) = "showDate(currentData.tanggal)">
                                <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="pekerjaan" class="col-sm-4 col-form-label">Jenis Pekerjaan</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" id="pekerjaan" required [(ngModel)]="currentData.jenis_pekerjaan" name="pekerjaan">
                        </div>
                    </div>
                <div class="form-group row">
                    <label for="jumlah" class="col-sm-4 col-form-label">Jumlah</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="jumlah" required [(ngModel)]="currentData.jumlah" name="jumlah">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="lab" class="col-sm-4 col-form-label">Lab</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="perawatan" required [(ngModel)]="currentData.lab" name="lab">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="pekerjaan" class="col-sm-4 col-form-label">Biaya</label>
                    <div class="col-sm-7">
                        <input type="text" class="form-control" id="biaya" required [(ngModel)]="currentData.biaya" name="biaya" placeholder="Rp ">
                    </div>
                </div>
                <fieldset class="form-group">
                <div class="row">
                    <legend class="col-form-label col-sm-4 pt-0">DPJP</legend>
                    <div class="col-sm-7">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="gridRadios" id="kamil" value="drg.Muhammad Kamil Nur" (change)="radioChangeHandler($event)">
                            <label class="form-check-label" for="kamil">
                            drg.Muhammad Kamil Nur
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="gridRadios" id="ammar" value="drg.Ammar Abdullah" (change)="radioChangeHandler($event)">
                            <label class="form-check-label" for="ammar">
                            drg.Ammar Abdullah
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="gridRadios" id="ihsan" value="drg.Muhammad Ihsan" (change)="radioChangeHandler($event)">
                            <label class="form-check-label" for="ihsan">
                            drg.Muhammad Ihsan
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="gridRadios" id="resya" value="drg.Resya Permatasari" (change)="radioChangeHandler($event)">
                            <label class="form-check-label" for="resya">
                            drg.Resya Permatasari
                            </label>
                        </div>
                    </div>
                </div>
                </fieldset>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-warning" (click)="modal.close('Save click');updatePasien()">Simpan</button>
            </div>
        </ng-template>   
</div>
