<div class="container-fluid">
    <div class="container col-md-9">
       <div *ngIf="currentPasien">
        <h1>{{header}}, {{currentPasien.nama_pasien}}</h1>
    
        <div id="data">
            <div class="row">
                <h6 class="col-sm-3">ID Pasien </h6>
                <p>{{currentPasien.pasien_id}}</p>
            </div>
            <div class="row">
                <h6 class="col-sm-3">Jenis Kelamin </h6>
                <p>{{currentPasien.jenis_kelamin}}</p>
            </div>
            <div class="row">
                <h6 class="col-sm-3">TTL </h6>
                <p>{{currentPasien.tempat_lahir}}, {{currentPasien.tanggal_lahir}}</p>
            </div>
            <div class="row">
                <h6 class="col-sm-3">No.Hp </h6>
                <p>{{currentPasien.no_hp}}</p>
            </div>
            <div class="row">
                <h6 class="col-sm-3">Pekerjaan </h6>
                <p>{{currentPasien.pekerjaan}}</p>  
            </div>
            <div class="row">
                <h6 class="col-sm-3">Alamat </h6>
                <p>{{currentPasien.alamat}}</p>
            </div>
            <div class="row">
                <h6 class="col-sm-3">Penyakit Sistemik </h6>
                <p>{{currentPasien.riwayat_penyakit_sistemik}}</p>
            </div>
            <div class="row">
                <h6 class="col-sm-3">Alergi Obat/Makanan </h6>
                <p>{{currentPasien.alergi_obat}}</p>
            </div>
            <div class="row">
                <h6 class="col-sm-3">Info Klinik </h6>
                <p>{{currentPasien.info_klinik}}</p>
            </div>
            <div style="margin-bottom: 1%;margin-top: 1%; position: relative; margin-left: 85%;">
                <button class="btn btn-warning btn-lg" routerLink="/pasien/update-data-pasien/{{currentPasien.pasien_id}}">Update Data</button>
            </div>
        </div>
        
    </div> 
    </div>
    

    <div *ngIf="currentPasien">
        <div class="container-fluid col-md-11">
            <div class="card" >
                <div class="card-header">
                    <h3 class="card-title">Data konsultasi pasien</h3>
                    <!-- <div class="card-tools">
                        <div class="input-group input-group-sm" style="width: 200px;">
                            <input type="text"
                            class="form-control"
                            placeholder="Cari berdasarkan nama"
                            [(ngModel)]="nama"
                            (keydown.enter)="searchNama()"
                            (keyup)="searchNama()"
                            />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-outline-secondary" (click)="searchNama()"><fa-icon [icon]="faSearch"></fa-icon></button>
                            </div>
                        </div>
                    </div> -->
                </div>
                
            
                <div class="card-body table-responsive p-0 " style="height: 430px">
                    <table class="table table-head-fixed table-hover">
                    <thead>
                        <tr>
                        <th scope="col">No.</th>
                        <th scope="col">ID</th>
                        <th scope="col">Tanggal</th>
                        <th scope="col">Unsur/Regio</th>
                        <th scope="col">Keluhan</th>
                        <th scope="col">Perawatan</th>
                        <th scope="col" >Keterangan</th>
                        <th scope="col">Rencana Perawatan</th>
                        <th scope="col">Perawat</th>
                        <th scope="col">Biaya</th>
                        <th scope="col">DPJP</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <!-- <tr *ngFor="let konsul of konsuls;let i = index" [class.active]="i == currentIndex" (click)="setActivePasien(pasien, i)" [routerLink]="[ '/pasien', pasien.pasien_id ]"> -->
                    <tr *ngFor="let konsul of konsuls;let i = index" >
                        <th scope="row">{{ i + 1 }}</th>
                        <td> {{konsul.riwayat_id}}</td>
                        <td> {{konsul.tanggal}} </td>
                        <td> {{konsul.unsur_regio}} </td>
                        <td> {{konsul.keluhan}} </td>
                        <td> {{konsul.perawatan}} </td>
                        <td id="ket"> {{konsul.keterangan}} </td>
                        <td> {{konsul.rencana_perawatan}}</td>
                        <td> {{konsul.perawat}}</td>
                        <td> {{konsul.biaya | currency : 'IDR' : 'Rp. '}} </td>
                        <td> {{konsul.dpjp}} </td>
                        <td><button class="btn btn-sm btn-warning" (click)="open(content);setActiveRiwayat(konsul, i)">Update</button></td>
                    </tr>
                    </tbody>
                    </table>
                </div>
                
        </div>
    </div>

    <div class="container-fluid button col-md-4">
        <button class="btn btn-primary btn-lg" routerLink="/pasien/{{currentPasien.pasien_id}}/konsultasi">Tambah Pemeriksaan</button>
        <button class="btn btn-success btn-lg" routerLink="/pasien/input-data-lab/{{currentPasien.pasien_id}}">Input Data Lab</button>
    </div>
    
    <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Update Riwayat Kunjungan</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form style="margin-right: -8%;">
            <div class="form-group row">
                <label for="inputPassword3" class="col-sm-4 col-form-label">Tanggal Pemeriksaan</label>
                <div class="col-sm-7">
                  <div class="input-group">
                    <input class="form-control" placeholder="{{currentRiwayat.tanggal}}" name="dp" [(ngModel)]="currentRiwayat.tanggal" ngbDatepicker #d="ngbDatepicker" (ngModelChange) = "showDate(currentRiwayat.tanggal)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                    <!-- <p>Adalah {{date}}</p> -->
                  </div>
                </div>
              </div>
            <div class="form-group row">
                <label for="unsur" class="col-sm-4 col-form-label">Unsur/Regio</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="unsur" [(ngModel)]="currentRiwayat.unsur_regio" required name="unsur">
                </div>
            </div>
            <div class="form-group row">
                <label for="keluhan" class="col-sm-4 col-form-label">Keluhan/Diagnosa</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="keluhan" required [(ngModel)]="currentRiwayat.keluhan" name="keluhan">
                </div>
              </div>
            <div class="form-group row">
                <label for="tekanan_darah" class="col-sm-4 col-form-label">Tekanan Darah</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control" id="tekanan_darah" required [(ngModel)]="currentRiwayat.tekanan_darah" name="tekanan_darah">
                </div>
            </div>
            <div class="form-group row">
                <label for="obat" class="col-sm-4 col-form-label">Obat yang sedang dikonsumsi</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control" id="obat" required [(ngModel)]="currentRiwayat.obat" name="obat">
                </div>
            </div>
            <div class="form-group row">
                <label for="noTelp" class="col-sm-4 col-form-label">Perawatan</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control" id="perawatan" required [(ngModel)]="currentRiwayat.perawatan" name="perawatan">
                </div>
            </div>
            <div class="form-group row">
                <label for="kett" class="col-sm-4 col-form-label">Keterangan</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control" id="kett" required [(ngModel)]="currentRiwayat.keterangan" name="ket">
                </div>
            </div>
            <div class="form-group row">
                <label for="pekerjaan" class="col-sm-4 col-form-label">Rencana Perawatan Selanjutnya</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control" id="rencana" required [(ngModel)]="currentRiwayat.rencana_perawatan" name="rencana">
                </div>
            </div>
            <div class="form-group row">
                <label for="perawat" class="col-sm-4 col-form-label">Perawat</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control" id="perawat" required [(ngModel)]="currentRiwayat.perawat" name="perawat">
                </div>
            </div>
            <div class="form-group row">
                <label for="pekerjaan" class="col-sm-4 col-form-label">Biaya</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control" id="biaya" required [(ngModel)]="currentRiwayat.biaya" name="biaya" placeholder="Rp ">
                </div>
            </div>
            <fieldset class="form-group">
            <div class="row">
                <legend class="col-form-label col-sm-4 pt-0">DPJP</legend>
                <div class="col-sm-7">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="gridRadios" id="kamil" value="drg.Muhammad Kamil Nur" (change)="radioChangeHandler($event)">
                        <label class="form-check-label" for="kamil">
                        drg.Muhammad Kamil Nur
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="gridRadios" id="ammar" value="drg.Ammar Abdullah" (change)="radioChangeHandler($event)">
                        <label class="form-check-label" for="ammar">
                        drg.Ammar Abdullah
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="gridRadios" id="ihsan" value="drg.Muhammad Ihsan" (change)="radioChangeHandler($event)">
                        <label class="form-check-label" for="ihsan">
                        drg.Muhammad Ihsan
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="gridRadios" id="resya" value="drg.Resya Permatasari" (change)="radioChangeHandler($event)">
                        <label class="form-check-label" for="resya">
                        drg.Resya Permatasari
                        </label>
                    </div>
                </div>
            </div>
            </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-warning" (click)="modal.close('Save click');updatePasien()">Simpan</button>
        </div>
    </ng-template>

</div>

