<div class="container-lg">
  <h1>{{header}}</h1>
    <div class="form">
        <form autocomplete="on">
            <div class="form-group row">
              <label for="namaPasien" class="col-sm-2 col-form-label"><span class="wajib">*</span> Nama Pasien </label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="namaPasien" required [(ngModel)]="pasien.nama" name="nama">
              </div>
            </div>
            <fieldset class="form-group">
              <div class="row">
                <label class="col-form-label col-sm-2 pt-0"><span class="wajib">* </span>Jenis Kelamin</label>
                <div class="col-sm-10">
                  <div class="form-check">
                    <div>
                      <input class="form-check-input" type="radio" name="gridRadios" id="pria" value="Pria" (change)="getJK($event)">
                      <label class="form-check-label" for="pria">
                        Pria
                      </label>
                    </div>
                    <div>
                      <input class="form-check-input" type="radio" name="gridRadios" id="wanita" value="Wanita" (change)="getJK($event)">
                      <label class="form-check-label" for="wanita">
                        Wanita
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="form-group row">
              <label for="tempatLahir" class="col-sm-2 col-form-label"><span class="wajib">* </span>Tempat Lahir</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="tempatLahir" required [(ngModel)]="pasien.tempatLahir" name="tempatLahir">
              </div>
            </div>
            <div class="form-group row">
                <label for="inputPassword3" class="col-sm-2 col-form-label"><span class="wajib">* </span>Tanggal Lahir</label>
                <div class="col-sm-10">
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="pasien.tanggalLahir" ngbDatepicker #d="ngbDatepicker" (ngModelChange) = "showDate(pasien.tanggalLahir)" [minDate]="{year: 1945, month:1, day: 1}">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                    <!-- <p>Adalah {{date}}</p> -->
                  </div>
                </div>
            </div>
            <div class="form-group row">
              <label for="alamat" class="col-sm-2 col-form-label"><span class="wajib">* </span>Alamat</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="alamat" required [(ngModel)]="pasien.alamat" name="alamat">
              </div>
            </div>
            <div class="form-group row">
              <label for="noTelp" class="col-sm-2 col-form-label"><span class="wajib">* </span>No. Telepon</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="noHp" required [(ngModel)]="pasien.noHp" name="noHp">
              </div>
            </div>
            <div class="form-group row">
              <label for="pekerjaan" class="col-sm-2 col-form-label"><span class="wajib">* </span>Pekerjaan</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="pekerjaan" required [(ngModel)]="pasien.pekerjaan" name="pekerjaan">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-2">Riwayat Penyakit Sistemik</div>
              <div class="col-sm-10">
                <div class="form-check" *ngFor="let item of checkboxDataList">
                    <input class="form-check-input" type="checkbox" [value]="item.label" (change)="getPenyakitId($event,item.label)" >
                  <label class="form-check-label" >
                    {{item.label}}
                  </label>
                </div>
                <input type="text" class="form-control" [(ngModel)]="penyakitDll" name="penyakit" placeholder="dll">

                <!-- <div *ngIf="">
                  <textarea rows="4" cols="50" name="dll" placeholder="Penyakit sistemik lainnya" ></textarea>
                </div> -->

              </div>
            </div>
            <fieldset class="form-group">
              <div class="row">
                <legend class="col-form-label col-sm-2 pt-0">Sumber Informasi Klinik</legend>
                <div class="col-sm-10">
                  <div class="form-check">
                    <div>
                      <input class="form-check-input" type="radio" name="gridRadios" id="ig" value="Instagram" (change)="getInfo($event)">
                      <label class="form-check-label" for="ig">
                        Instagram
                      </label>
                    </div>
                    <div>
                      <input class="form-check-input" type="radio" name="gridRadios" id="google" value="Google" (change)="getInfo($event)">
                      <label class="form-check-label" for="google">
                        Google
                      </label>
                    </div>
                    <div>
                      <input class="form-check-input" type="radio" name="gridRadios" id="kerabat" value="Kerabat" (change)="getInfo($event)"checked>
                      <label class="form-check-label" for="kerabat">
                        Kerabat
                      </label>
                    </div>
                    <div>
                      <input class="form-check-input" type="radio" name="gridRadios" id="rujukan" value="Rujukan" (change)="getInfo($event)"checked>
                      <label class="form-check-label" for="rujukan">
                        Rujukan Sejawat
                      </label>
                    </div>
                    <div>
                      <input class="form-check-input" type="radio" name="gridRadios" id="banner" value="Banner" (change)="getInfo($event)"checked>
                      <label class="form-check-label" for="banner">
                        Melihat Papan Praktek
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="form-group">
              <div class="row">
                <legend class="col-form-label col-sm-2 pt-0">Alergi Obat/Makanan</legend>
                <div class="col-sm-10">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="ya" (change)="radioChangeHandler($event)">
                    <label class="form-check-label" for="gridRadios1">
                      Ya
                    </label>
                    <div *ngIf="cekAlergi=='ya' ">
                      <input type="text" class="form-control" id="alergi" [(ngModel)]="pasien.alergi" name="alergi" placeholder="Jenis Obat / Makanan">
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="tidak" (change)="radioChangeHandler($event)" checked >
                    <label class="form-check-label" for="gridRadios2">
                      Tidak
                    </label>
                  </div>
                  
                </div>
              </div>
            </fieldset>
            
            <div class="form-group row">
              <div class="col-sm-10">
                <button type="submit" class="btn btn-primary" (click)="savePasien()"> Daftar</button>
              </div>
            </div>
          </form>
    </div>
    
</div>
