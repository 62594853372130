<div class="container-lg">
    <h1>{{header}}</h1>
      <div class="form">
          <form autocomplete="on">
              <!-- <div class="form-group row">
                <label for="namaPasien" class="col-sm-2 col-form-label">Nama Pasien</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="namaPasien" required [(ngModel)]="riwayat.nama" name="nama">
                </div>
              </div> -->
              <div class="form-group row">
                <label for="inputPassword3" class="col-sm-2 col-form-label"><span class="wajib">* </span>Tanggal Pemeriksaan</label>
                <div class="col-sm-10">
                  <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="riwayat.tanggal" ngbDatepicker #d="ngbDatepicker" (ngModelChange) = "showDate(riwayat.tanggal)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                    <!-- <p>Adalah {{date}}</p> -->
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="tempatLahir" class="col-sm-2 col-form-label"><span class="wajib">* </span>Unsur/Regio</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="unsurRegio" required [(ngModel)]="riwayat.unsur_regio" name="unsurRegio">
                </div>
              </div>
              
              <div class="form-group row">
                <label for="keluhan" class="col-sm-2 col-form-label"><span class="wajib">* </span>Keluhan/Diagnosa</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="keluhan" required [(ngModel)]="riwayat.keluhan" name="keluhan">
                </div>
              </div>
              <div class="form-group row">
                <label for="tekanan_darah" class="col-sm-2 col-form-label">Tekanan Darah</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="tekanan_darah" required [(ngModel)]="riwayat.tekanan_darah" name="tekanan_darah">
                </div>
              </div>
              <div class="form-group row">
                <label for="obat" class="col-sm-2 col-form-label">Obat yang sedang dikonsumsi</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="obat" required [(ngModel)]="riwayat.obat" name="obat">
                </div>
              </div>
              <div class="form-group row">
                <label for="noTelp" class="col-sm-2 col-form-label"><span class="wajib">* </span>Perawatan</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="perawatan" required [(ngModel)]="riwayat.perawatan" name="perawatan">
                </div>
              </div>
              <div class="form-group row">
                <label for="ket" class="col-sm-2 col-form-label">Keterangan</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="ket" required [(ngModel)]="riwayat.keterangan" name="ket">
                </div>
              </div>
              <div class="form-group row">
                <label for="pekerjaan" class="col-sm-2 col-form-label"><span class="wajib">* </span>Rencana Perawatan Selanjutnya</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="rencana" required [(ngModel)]="riwayat.rencana_perawatan" name="rencana">
                </div>
              </div>
              <div class="form-group row">
                <label for="perawat" class="col-sm-2 col-form-label"><span class="wajib">* </span>Perawat</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="perawat" required [(ngModel)]="riwayat.perawat" name="perawat">
                </div>
              </div>
              <div class="form-group row">
                <label for="pekerjaan" class="col-sm-2 col-form-label"><span class="wajib">* </span>Biaya</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="biaya" required [(ngModel)]="riwayat.biaya" name="biaya" placeholder="Rp ">
                </div>
              </div>
              <!-- <div class="form-group row">
                <div class="col-sm-2">Riwayat Penyakit Sistemik</div>
                <div class="col-sm-10">
                  <div class="form-check" *ngFor="let item of checkboxDataList">
                      <input class="form-check-input" type="checkbox" [value]="item.label" (change)="getPenyakitId($event,item.label)">
                    <label class="form-check-label" >
                      {{item.label}}
                    </label>
                  </div>
                  <input type="text" class="form-control" [(ngModel)]="penyakitDll" name="penyakit" placeholder="dll">
  
                  <div *ngIf="">
                    <textarea rows="4" cols="50" name="dll" placeholder="Penyakit sistemik lainnya" ></textarea>
                  </div>
  
                </div>
              </div> -->
              <fieldset class="form-group">
                <div class="row">
                  <legend class="col-form-label col-sm-2 pt-0"><span class="wajib">* </span>DPJP</legend>
                  <div class="col-sm-10">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="kamil" value="drg.Muhammad Kamil Nur" (change)="radioChangeHandler($event)">
                      <label class="form-check-label" for="kamil">
                        drg.Muhammad Kamil Nur
                      </label>
                      <!-- <div *ngIf="cekAlergi=='ya' ">
                        <input type="text" class="form-control" id="alergi" [(ngModel)]="pasien.alergi" name="alergi" placeholder="Jenis Obat">
                      </div> -->
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="ammar" value="drg.Ammar Abdullah" (change)="radioChangeHandler($event)">
                      <label class="form-check-label" for="ammar">
                        drg.Ammar Abdullah
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="ihsan" value="drg.Muhammad Ihsan" (change)="radioChangeHandler($event)">
                      <label class="form-check-label" for="ihsan">
                        drg.Muhammad Ihsan
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="gridRadios" id="resya" value="drg.Resya Permatasari" (change)="radioChangeHandler($event)">
                      <label class="form-check-label" for="resya">
                        drg.Resya Permatasari
                      </label>
                    </div>
                    
                  </div>
                </div>
              </fieldset>
              
              <div class="form-group row">
                <div class="col-sm-10">
                  <button type="submit" class="btn btn-primary" (click)="saveKonsul()">Simpan</button>
                </div>
              </div>
            </form>
      </div>
      
  </div>
  