<div class="container-md">
    <h1>{{header}}</h1>
    <div class="container col-md-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Tanggal : {{tanggal}}</h4>
                <!-- <h5 class="card-title">Total Kunjungan : {{kunjunganTotal}}</h5>
                <h5 class="card-title">Total Pendapatan : {{pendapatanTotal}}</h5> -->
                <div class="card-tools">
                    <div class="col-sm-10">
                        <div class="input-group">
                            <input class="form-control" placeholder={{tanggal}} name="dp" [(ngModel)]="tanggal" ngbDatepicker #d="ngbDatepicker" (ngModelChange) = "showDate(tanggal);searchTanggal()" [minDate]="{year: 1945, month:1, day: 1}">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        </div>

    <div class="container col-md-12">
        <div class="card card-dr">
            <div class="card-header">
                <h4 class="dr">drg.Muhammad Kamil Nur</h4>
            </div>
            <div class="card-body table-responsive p-0" style="height: 430px;" >
                <table class="table table-head-fixed">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">ID</th>
                            <th scope="col">Nama Pasien</th>
                            <th scope="col">Unsur/Regio</th>
                            <th scope="col">Perawatan</th>
                            <th scope="col">Perawat</th>
                            <th scope="col">Biaya</th>
                            <!-- <th scope="col">DPJP</th> -->
                            </tr> 
                    </thead>
                    <tbody>
                        <tr *ngFor="let kunjungan of kunjungansKamil;let i = index">
                            <th scope="row">{{ i + 1 }}</th>
                            <td> {{kunjungan.Pasien.pasien_id}}</td>
                            <td> {{kunjungan.Pasien.nama_pasien}} </td>
                            <td> {{kunjungan.unsur_regio}} </td>
                            <td> {{kunjungan.perawatan}} </td>
                            <td> {{kunjungan.perawat}} </td>
                            <td> {{kunjungan.biaya | currency : 'IDR' : 'Rp. '}} </td>
                            <!-- <td> {{kunjungan.dpjp}} </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <h6 class="card-text">Total Pendapatan: {{totalPKamil | currency : 'IDR' : 'Rp. '}}</h6>
                <h6 class="card-text">Jumlah Kunjungan: {{totalKKamil}}</h6>
            </div>
        </div>
    </div>

    <div class="container col-md-12">
        <div class="card card-dr">
            <div class="card-header">
                <h4 class="dr">drg.Ammar Abdullah</h4>
            </div>
            <div class="card-body table-responsive p-0" style="height: 430px;" >
                <table class="table table-head-fixed">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">ID</th>
                            <th scope="col">Nama Pasien</th>
                            <th scope="col">Unsur/Regio</th>
                            <th scope="col">Perawatan</th>
                            <th scope="col">Perawat</th>
                            <th scope="col">Biaya</th>
                            <!-- <th scope="col">DPJP</th> -->
                            </tr> 
                    </thead>
                    <tbody>
                        <tr *ngFor="let kunjungan of kunjungansAmmar;let i = index">
                            <th scope="row">{{ i + 1 }}</th>
                            <td> {{kunjungan.Pasien.pasien_id}}</td>
                            <td> {{kunjungan.Pasien.nama_pasien}} </td>
                            <td> {{kunjungan.unsur_regio}} </td>
                            <td> {{kunjungan.perawatan}} </td>
                            <td> {{kunjungan.perawat}} </td>
                            <td> {{kunjungan.biaya | currency : 'IDR' : 'Rp. '}} </td>
                            <!-- <td> {{kunjungan.dpjp}} </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <h6 class="card-text">Total Pendapatan: {{totalPAmmar | currency : 'IDR' : 'Rp. '}}</h6>
                <h6 class="card-text">Jumlah Kunjungan: {{totalKAmmar}}</h6>
            </div>
        </div>
    </div>

    <div class="container col-md-12">
        <div class="card card-dr">
            <div class="card-header">
                <h4 class="dr">drg.Muhammad Ihsan</h4>
            </div>
            <div class="card-body table-responsive p-0" style="height: 430px;" >
                <table class="table table-head-fixed">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">ID</th>
                            <th scope="col">Nama Pasien</th>
                            <th scope="col">Unsur/Regio</th>
                            <th scope="col">Perawatan</th>
                            <th scope="col">Perawat</th>
                            <th scope="col">Biaya</th>
                            <!-- <th scope="col">DPJP</th> -->
                            </tr> 
                    </thead>
                    <tbody>
                        <tr *ngFor="let kunjungan of kunjungansIhsan;let i = index">
                            <th scope="row">{{ i + 1 }}</th>
                            <td> {{kunjungan.Pasien.pasien_id}}</td>
                            <td> {{kunjungan.Pasien.nama_pasien}} </td>
                            <td> {{kunjungan.unsur_regio}} </td>
                            <td> {{kunjungan.perawatan}} </td>
                            <td> {{kunjungan.perawat}} </td>
                            <td> {{kunjungan.biaya | currency : 'IDR' : 'Rp. '}} </td>
                            <!-- <td> {{kunjungan.dpjp}} </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <h6 class="card-text">Total Pendapatan: {{totalPIhsan | currency : 'IDR' : 'Rp. '}}</h6>
                <h6 class="card-text">Jumlah Kunjungan: {{totalKIhsan}}</h6>
            </div>
        </div>
    </div>

    <div class="container col-md-12">
        <div class="card card-dr">
            <div class="card-header">
                <h4 class="dr">drg.Resya Permatasari</h4>
            </div>
            <div class="card-body table-responsive p-0" style="height: 430px;" >
                <table class="table table-head-fixed">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">ID</th>
                            <th scope="col">Nama Pasien</th>
                            <th scope="col">Unsur/Regio</th>
                            <th scope="col">Perawatan</th>
                            <th scope="col">Perawat</th>
                            <th scope="col">Biaya</th>
                            <!-- <th scope="col">DPJP</th> -->
                            </tr> 
                    </thead>
                    <tbody>
                        <tr *ngFor="let kunjungan of kunjungansResya;let i = index">
                            <th scope="row">{{ i + 1 }}</th>
                            <td> {{kunjungan.Pasien.pasien_id}}</td>
                            <td> {{kunjungan.Pasien.nama_pasien}} </td>
                            <td> {{kunjungan.unsur_regio}} </td>
                            <td> {{kunjungan.perawatan}} </td>
                            <td> {{kunjungan.perawat}} </td>
                            <td> {{kunjungan.biaya | currency : 'IDR' : 'Rp. '}} </td>
                            <!-- <td> {{kunjungan.dpjp}} </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <h6 class="card-text">Total Pendapatan: {{totalPResya | currency : 'IDR' : 'Rp. '}}</h6>
                <h6 class="card-text">Jumlah Kunjungan: {{totalKResya}}</h6>
            </div>
        </div>
    </div>

    
</div>