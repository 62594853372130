import { Component, OnDestroy, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './service/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'AdheesaFE';
  mediaSub:Subscription;
  deviceXs: boolean;
  constructor(public mediaObserver:MediaObserver, 
    public authenticationService: AuthenticationService){
      
    }
  ngOnInit(){
    this.mediaSub = this.mediaObserver.media$.subscribe((result:MediaChange)=>{
      console.log(result.mqAlias);
      this.deviceXs = result.mqAlias === 'xs' ? true : false;
    })
  }
  ngOnDestroy(){
    this.mediaSub.unsubscribe();
  }

  logout(){
    this.authenticationService.logout();
  }
}
