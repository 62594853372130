import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { KunjunganHarianComponent } from './pages/kunjungan-harian/kunjungan-harian.component';
import { DataLabComponent } from './pages/laboratorium/data-lab/data-lab.component';
import { RegisLaboratoriumComponent } from './pages/laboratorium/regis-laboratorium/regis-laboratorium.component';
import { LaporanBulananComponent } from './pages/laporan/laporan-bulanan/laporan-bulanan.component';
import { LaporanHarianComponent } from './pages/laporan/laporan-harian/laporan-harian.component';
import { DataPasienComponent } from './pages/pasien/data-pasien/data-pasien.component';
import { DetailPasienComponent } from './pages/pasien/detail-pasien/detail-pasien.component';
import { KonsulPasienComponent } from './pages/pasien/konsul-pasien/konsul-pasien.component';
import { RegisPasienComponent } from './pages/pasien/regis-pasien/regis-pasien.component';
import { UpdatePasienComponent } from './pages/pasien/update-pasien/update-pasien.component';


const routes: Routes = [
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'kunjunganHarian', component: KunjunganHarianComponent , canActivate: [AuthGuard]},
  {path: 'registrasi', component: RegisPasienComponent , canActivate: [AuthGuard]},
  {path: 'pasien', component: DataPasienComponent , canActivate: [AuthGuard]},
  {path: 'pasien', component: DetailPasienComponent , canActivate: [AuthGuard]},
  {path: 'pasien', component: KonsulPasienComponent , canActivate: [AuthGuard]},
  {path: 'pasien', component: UpdatePasienComponent , canActivate: [AuthGuard]},
  {path: 'laporan/bulanan', component: LaporanBulananComponent , canActivate: [AuthGuard]},
  {path: 'laporan/harian', component: LaporanHarianComponent , canActivate: [AuthGuard]},
  {path: 'data-lab', component: DataLabComponent , canActivate: [AuthGuard]},
  {path:'', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
