import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { CurrencyPipe } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { RegisPasienComponent } from './pages/pasien/regis-pasien/regis-pasien.component';
import { DataPasienComponent } from './pages/pasien/data-pasien/data-pasien.component';
import { KunjunganHarianComponent } from './pages/kunjungan-harian/kunjungan-harian.component';
import { HomeComponent } from './pages/home/home.component';
import { DetailPasienComponent } from './pages/pasien/detail-pasien/detail-pasien.component';
import { KonsulPasienComponent } from './pages/pasien/konsul-pasien/konsul-pasien.component';
import { UpdatePasienComponent } from './pages/pasien/update-pasien/update-pasien.component';
import { RegisLaboratoriumComponent } from './pages/laboratorium/regis-laboratorium/regis-laboratorium.component';
import { DataLabComponent } from './pages/laboratorium/data-lab/data-lab.component';
import { LaporanHarianComponent } from './pages/laporan/laporan-harian/laporan-harian.component';
import { LaporanBulananComponent } from './pages/laporan/laporan-bulanan/laporan-bulanan.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { from } from 'rxjs';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    RegisPasienComponent,
    DataPasienComponent,
    KunjunganHarianComponent,
    HomeComponent,
    DetailPasienComponent,
    KonsulPasienComponent,
    UpdatePasienComponent,
    RegisLaboratoriumComponent,
    DataLabComponent,
    LaporanHarianComponent,
    LaporanBulananComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent
  ],
  imports: [
    FontAwesomeModule,
    FormsModule,
    BrowserModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: '',
        redirectTo:'home',
        pathMatch:'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'registrasi',
        component: RegisPasienComponent
      },
      {
        path: 'pasien',
        component: DataPasienComponent
      },
      {
        path: 'kunjunganHarian',
        component: KunjunganHarianComponent
      },
      {
        path: 'pasien/:pasien_id',
        component:DetailPasienComponent
      },
      {
        path: 'pasien/:pasien_id/konsultasi',
        component:KonsulPasienComponent
      },
      {
        path: 'pasien/update-data-pasien/:pasien_id',
        component:UpdatePasienComponent
      },
      {
        path: 'pasien/input-data-lab/:pasien_id',
        component:RegisLaboratoriumComponent,
      },
      {
        path: 'data-lab',
        component:DataLabComponent
      },
      {
        path:'laporan/harian',
        component:LaporanHarianComponent
      },
      {
        path:'laporan/bulanan',
        component:LaporanBulananComponent
      }
    ]),
    BrowserAnimationsModule
  ],
  providers: [DatePipe, CurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
