<footer class="footer">
    <div class="container">
        <div class="content">
            <h5 class="footer-content" id="info">Sistem Informasi <br> Adheesa Dental Care Makassar</h5>
            <hr>
            <p class="footer-content" id="devp">Powered by <span id="dev">Muyums Dev.</span></p>
            <p class="footer-content" id="ver">Version 1.0.0</p>
        </div>
        
    </div>
</footer>