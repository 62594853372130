<mat-toolbar color="primary">
    <mat-toolbar-row fxLayoutAlign="space-between center">
        <div fxLayout="row">
            <button mat-button routerLink="/home">
  
                <img src="./assets/img/Adheesa1.png" width="60" height="60" class="d-inline-block align-center" alt="">
                <span class="logo">Adheesa Dental Care</span>
        
        </button>
        <div *ngIf="!deviceXs" fxLayoutAlign="align-center">
        <button mat-button routerLink="/registrasi">Registrasi</button>
        <button mat-button routerLink="/pasien" > Data Base Pasien</button>
        <button mat-button routerLink="/kunjunganHarian" >Kunjungan Harian</button>
        </div>
    </div>
    <div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon *ngIf="deviceXs">more_vert</mat-icon>
            <span *ngIf="!deviceXs">Laporan</span>
        </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="laporan/harian">
              <span>Harian</span>
            </button>
            <button mat-menu-item routerLink="laporan/bulanan">
              <span>Bulanan</span>
            </button>
            <button mat-menu-item routerLink="data-lab">
              <span>Laboratorium</span>
            </button>
          </mat-menu>
          
    </div>
    </mat-toolbar-row>
  
  <mat-toolbar-row *ngIf="deviceXs" fxLayoutAlign="space-between end">
    <button mat-button routerLink="/registrasi" >Registrasi</button>
    <button mat-button routerLink="/pasien">Data Base Pasien</button>
    <button mat-button routerLink="/kunjunganHarian">Kunjungan Harian</button>
  </mat-toolbar-row>
  </mat-toolbar>
  